<template>
  <ul>
    <component
      :is="resolveNavItemComponent(item)"
      v-for="item in items"
      :key="item.header || item.title"
      :item="item"
    />

    <VerticalNavMenuLink class="user-item" :item="userItem" @click.native="userItemClicked = !userItemClicked" />
    <li class="user-item__dropdown" :class="{'_visible' : userItemClicked}">
      <UserDropdown />
    </li>
  </ul>
</template>

<script>
import { resolveVerticalNavMenuItemComponent as resolveNavItemComponent } from '@core/layouts/utils'
import { provide, ref } from '@vue/composition-api'
import VerticalNavMenuHeader from '../vertical-nav-menu-header'
import VerticalNavMenuLink from '../vertical-nav-menu-link/VerticalNavMenuLink.vue'
import VerticalNavMenuGroup from '../vertical-nav-menu-group/VerticalNavMenuGroup.vue'
import UserDropdown from "@/components/UserDropdown"

export default {
  components: {
    VerticalNavMenuHeader,
    VerticalNavMenuLink,
    VerticalNavMenuGroup,
    UserDropdown
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  setup() {
    provide('openGroups', ref([]))

    const userData = JSON.parse(localStorage.getItem('userData'))

    const userItem = {
      icon: 'UserIcon',
      title: userData.email
    }

    const userItemClicked = ref(false);

    return {
      resolveNavItemComponent,
      userItem,
      userData,
      userItemClicked
    }
  },
}
</script>

<style lang="scss">
.user-item {
  margin-top: auto;

  & .menu-title {
    max-width: 135px;
  }

  &__dropdown {
    position: absolute !important;
    bottom: 17px;
    left: 190px;
  }
}
</style>
