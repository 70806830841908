export default [
  {
    title: 'Dashboard',
    route: 'dashboard',
    icon: 'TrelloIcon'
  },
  {
    title: 'Users',
    route: 'users-table',
    icon: 'UsersIcon'
  },
  {
    title: 'Advertisers',
    route: 'advertisers',
    icon: 'dsps',
    custom: true
  },
  {
    title: 'Publishers',
    route: 'publishers',
    icon: 'pub',
    custom: true
  },
  {
    title: 'Advertising Campaigns',
    route: 'advertising-campaigns',
    icon: 'advertising-campaigns',
    custom: true
  },
  {
    title: 'Platforms',
    route: 'platforms',
    icon: 'platforms',
    custom: true
  },
  {
    title: 'Placements',
    route: 'placements',
    icon: 'placements',
    custom: true
  },
  {
    title: 'Statistics',
    route: 'manager-statistic',
    icon: 'stat',
    custom: true
  },
  // {
  //   title: 'Statistics',
  //   route: 'statistics',
  //   icon: "BarChartIcon",
  // },
  {
    title: 'Statistic Publisher',
    route: 'publisher-statistic',
    icon: 'pub_stats',
    custom: true
  },
  {
    title: 'Statistic DSP',
    route: 'dsp-statistic',
    icon: 'dsp_stats',
    custom: true

  },
  {
    title: 'Statistics Advertiser',
    route: 'advertiser-statistic',
    icon: 'advertiser_stats',
    custom: true
  },
  // {
  //   title: 'Finance',
  //   route: 'finance',
  //   icon: 'finance',
  //   custom: true,
  // },
  {
    title: 'Player',
    route: 'player',
    icon: 'PlayIcon'
  },
  {
    title: 'Logs',
    route: 'logs',
    icon: 'logs',
    custom: true
  }
]
