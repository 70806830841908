<template>
  <li
    v-if="canViewVerticalNavMenuLink(item) && ignoreLinksForAdmin(item.route) || item.icon === 'UserIcon'"
    class="nav-item"
    :class="{
      'active': isActive,
      'disabled': item.disabled
    }"
  >
    <b-link
      v-bind="linkProps"
      class="d-flex align-items-center"
    >
      <CustomIcons v-if="item.custom" :name="item.icon"></CustomIcons>
      <feather-icon v-else :icon="item.icon || 'CircleIcon'" />
      <span class="menu-title text-truncate">{{ t(item.title) }}</span>
      <b-badge
        v-if="item.tag"
        pill
        :variant="item.tagVariant || 'primary'"
        class="mr-1 ml-auto"
      >
        {{ item.tag }}
      </b-badge>
    </b-link>
  </li>
</template>

<script>
import { useUtils as useAclUtils } from '@core/libs/acl'
import { BLink, BBadge } from 'bootstrap-vue'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import useVerticalNavMenuLink from './useVerticalNavMenuLink'
import CustomIcons from "@/components/CustomIcons"
import mixinVerticalNavMenuLink from './mixinVerticalNavMenuLink'

export default {
  components: {
    BLink,
    CustomIcons,
    BBadge,
  },
  mixins: [mixinVerticalNavMenuLink],
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  setup(props) {

    const roleId = parseInt(localStorage.getItem('userRoleId'), 10)

    const ignoreLinksForAdmin = (subject) => {
      return !(['publisher-statistic', 'dsp-statistic', 'advertiser-statistic'].includes(subject) && roleId === 1);

    }

    const { isActive, linkProps, updateIsActive } = useVerticalNavMenuLink(props.item)
    const { t } = useI18nUtils()
    const { canViewVerticalNavMenuLink } = useAclUtils()

    return {
      isActive,
      linkProps,
      updateIsActive,

      // ACL
      canViewVerticalNavMenuLink,
      ignoreLinksForAdmin,

      // i18n
      t,
    }
  },

}
</script>
